@import "../assets/sass/helper";

.cursor {
	@include media_991(min) {
		position: fixed;
		width: 20px;
		height: 20px;
		pointer-events: none;
		background-color: #fff;
		border-radius: 50%;
		transform-origin: left top;
		z-index: 999999;
		transform: translate(-50%, -50%);
		mix-blend-mode: difference;
	}
	@include media_991 {
		display: none;
	}
}
